// FIX ME
import { useTheme } from '@/composables/useTheme';
const { colors } = useTheme();
import { AppPreloaderModelValue } from '@/types/components/loaders';

let appIsLoading: AppPreloaderModelValue = false;

export const setCSSStyles = (): void => {
  const root = document.documentElement;

  appIsLoading = true;

  Object.entries(colors.value).forEach(([key, value]): void => {
    root.style.setProperty(`--${key}`, value as string);
  });

  // FIXME: Demo-вариант снятия прелоадера
  setTimeout((): void => {
    appIsLoading = false;
  }, 2000);
};