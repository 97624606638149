<template>
  <transition name="sm-notify-animate">
    <div
      v-show="showNotify"
      class="sm-notify"
      :class="notifyClassList"
    >
      <header
        v-if="title"
        class="sm-notify__header"
      >
        <h3
          v-if="title"
          class="sm-notify__title"
        >{{ title }}</h3>
      </header>

      <p class="sm-notify__text">{{ text }}</p>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { ref, onMounted, toRefs, computed } from "vue";
import { INotifyProps } from "@/types/components/notify";
import { Class } from "@/types/common";

const props = withDefaults(defineProps<INotifyProps>(), {
  type: 'neutral',
  title: '',
  delay: 6000,
});

const {
  type,
  title,
  text,
  delay,
} = toRefs(props);

const showNotify = ref<boolean>(false);

const notifyClassList = computed((): Class => {
  return `sm-notify--${type.value}`
});

const close = (): void => {
  showNotify.value = false;
}

onMounted(() => {
  showNotify.value = true;

  setTimeout(() => {
    close();
  }, delay.value);
});
</script>

<style lang="scss">
.sm-notify {
  display: flex;
  flex-direction: column;

  width: 500px;
  padding-block: var(--padding);
  margin-bottom: var(--margin-s);

  border-width: 1px;
  border-style: solid;
  border-radius: var(--stroke);

  box-shadow: 0px 4px 8px 0px #3C507714;
  box-shadow: 0px 8px 16px 0px #152D5B1C;
}

$types: (
  'success': 'Success',
  'warning': 'Warning',
  'error': 'Error',
  'info': 'Info',
  'neutral': 'Neutral',
);

@each $key, $type in $types {
  .sm-notify--#{$key} {
    color: var(--#{$type});
    background-color: var(--BG#{$type});
    border-color: var(--Stroke#{$type});
  }
}

.sm-notify__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-inline: var(--padding);
  margin-bottom: var(--padding);
}

.sm-notify__title {
  @include h3;

  flex-shrink: 1;

  margin: 0;
}

.sm-notify__close {
  flex-shrink: 0;

  cursor: pointer;
}

.sm-notify__text {
  margin: 0;
  padding-inline: var(--padding);
}

// Animation
.sm-notify-animate-enter-from,
.sm-notify-animate-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.sm-notify-animate-enter-active,
.sm-notify-animate-leave-active {
  @include transition(('transform', 'opacity'));
}
</style>